
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { delGiftSend, exportSendList, getShopGiftAll, getSendList, editShopGiftSend, addShopGiftSend } from "@/api/request/item";
import { cloneDeep } from "lodash";

//组件
@Component({
  name: "GiftSend",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private giftOptions: any[] = [];
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    row: 20,
    page: 1,
  };

  //创建
  created() {
    //获取礼包参数
    this.getGiftOptions();

    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getSendList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private addHandler(): void {
    //数据赋值
    this.form = {
      //临时数据
      gift_name: "",

      //请求数据
      uids: [], //用户ID
      id: undefined, //ID
      target: 1, //礼包类型
      type: undefined, //发放方式
      gift_id: undefined, //礼包ID
      task: false, //是否新手引导
      send_time: "", //发放日期
    };
    this.tags = [];

    //显示对话框
    this.dialogVisible = true;
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await exportSendList(this.listQuery);

    //保存数据
    saveAs(data, "礼品发放列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取礼包参数
  private async getGiftOptions() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const res = await getShopGiftAll();

    //数据赋值
    this.giftOptions = res.data;
  }

  //处理删除
  private handleDel(id: any): void {
    //显示提示
    this.$confirm(`您确定要删除该礼品？`, "警告", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //删除
        const res: any = await delGiftSend({ id });

        //删除成功
        if (res.status == 0) {
          //显示提示
          this.$message.success("删除成功");

          //获取列表
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.tags = [];
    if (row.ids !== "") {
      JSON.parse(row.ids).forEach((item: any) => {
        this.tags.push(String(item));
      });
    }
    this.form = cloneDeep(row);
    this.form.task = Number(this.form.task) == 1 ? true : false;

    //显示对话框
    this.dialogVisible = true;
  }

  //用户类型
  private formatTarget(row: any): any {
    if (row.target === 1) return JSON.parse(row.ids).join(",");
    if (row.target === 2) return "全部用户";
    if (row.target === 3) return "全部博主";
    if (row.target === 4) return "全部男用户";
    if (row.target === 5) return "全部女用户";
    if (row.target === 6) return "新注册男用户";
    if (row.target === 7) return "新注册女用户";
    if (row.target === 8) return "全部新注册用户";
  }

  //------------------------------------------ 礼包发放 ------------------------------------------
  //定义变量
  private tags: any[] = [];
  private tagInput: string = "";
  private tagInputMore: string = "";
  private dialogVisible: boolean = false;

  //规则
  private rules: any = {
    type: [{ required: true, message: "请选择类型", trigger: "blur" }],
    gift_id: [{ required: true, message: "请选择礼包", trigger: "blur" }],
    send_time: [{ required: true, message: "请输入发送时间", trigger: "blur" }],
  };

  //定义变量
  private form: any = {
    //临时数据
    gift_name: "",

    //请求数据
    uids: [], //用户ID
    id: undefined, //ID
    target: 1, //礼包类型
    type: undefined, //发放方式
    gift_id: undefined, //礼包ID
    task: false, //是否新手引导
    send_time: "", //发放日期
  };

  //处理保存
  private handlePush() {
    //选择发送对象
    if (!this.tags.length && this.form.target === 1) {
      return this.$message.error("请您输入要发布的对象");
    }

    //数据赋值
    if (this.tags.length && this.form.target === 1) {
      this.form.uids = [];
      this.tags.forEach((item: any) => {
        this.form.uids.push(Number(item));
      });
    } else {
      this.form.uids = [];
    }

    //是否新手任务
    if (this.form.task) {
      this.form.task = 1;
    } else {
      this.form.task = 0;
    }

    //验证表单
    (this.$refs.ruleForm as any).validate(async (valid: any) => {
      if (valid) {
        //请求参数
        const res: any = this.form.id ? await editShopGiftSend(this.form) : await addShopGiftSend(this.form);

        //请求成功
        if (res.status == 0) {
          //显示提示
          this.form.id ? this.$message.success("编辑成功") : this.$message.success("新增成功");

          //隐藏对话框
          this.dialogVisible = false;

          //获取列表
          this.getList();
        }
      } else {
        return false;
      }
    });
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;
  }

  //添加ID
  private addTag(type: any): void {
    //定义变量
    let errorList: any = [];

    //批量添加
    if (type === "more") {
      //添加数据
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item) => {
          //移仓处理
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());

          //数据赋值
          this.tags.push(item.trim());

          //清空输入框
          this.tagInputMore = "";
        });

      //显示提示
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      //验证ID
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        //数据赋值
        this.tags.push(this.tagInput.trim());

        //清空输入框
        this.tagInput = "";
      } else {
        //显示提示
        this.$message.error("请输入正确形式的id");

        //清空输入框
        this.tagInput = "";
      }
    }
  }

  //删除ID
  private removeTag(index: any): void {
    //数据赋值
    this.tags.splice(index, 1);
  }

  //------------------------------------------ 视频详情 ------------------------------------------
  //定义变量
  private videoUrl: string = "";
  private videoDialogVisible: boolean = false;
}
